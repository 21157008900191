import React from "react"
import "../pages/404.scss"
import { Divider } from "@mui/material"
import { useContext } from "react"
import { Context } from "../store/ContextProvider"
import { UILink } from "../components"

const NotFoundPage = () => {
  const {
    state: { isDarkThemeActive },
  } = useContext(Context)
  return (
    <div className="not-found">
      <div className="container">
        <Divider flexItem={true} light={isDarkThemeActive}>
          <h3 className="text--color">Error</h3>
          <h1>404</h1>
        </Divider>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        <UILink to="/">
          <span className="text--light">Go Home</span>
        </UILink>
      </div>
    </div>
  )
}

export default NotFoundPage
